import produce from 'immer';
import app from '@/core/api';
import { addYears, endOfDay, startOfDay } from 'date-fns';
import { Activity } from '../../../backend/src/models/activities.model';
import { StoreSlice } from '.';
import { SuggestionsParams } from './common.types';

export const isValidDate = (d?: unknown) =>
  d instanceof Date && !Number.isNaN(d);

export type PeriodsFilterValue = {
  $gte: Date;
  $lte: Date;
  key?: string;
  inputDateValue?: string;
};

export interface ActivitySuggestion {
  _score: number;
  value: string;
  count: number;
  field: 'category.title' | 'title';
}

export interface ActivitiesSlice {
  all: Activity[];
  current?: string;
  suggestions: ActivitySuggestion[];
  currentSuggestion?: ActivitySuggestion;
  currentSearch?: string;
  currentPeriod?: PeriodsFilterValue;
  find(params): Promise<void>;
  findSuggestions(params?: SuggestionsParams): Promise<void>;
  setCurrentSuggestion(suggestion: ActivitySuggestion): void;
  setCurrentSearch(value: string): void;
  setCurrentPeriod(value: PeriodsFilterValue): void;
}

const activitiesSlice: StoreSlice<ActivitiesSlice> = (set, get) => ({
  all: [],
  current: undefined,
  suggestions: [],
  currentSuggestion: undefined,
  currentSearch: undefined,
  currentPeriod: {
    $gte: startOfDay(new Date()),
    $lte: endOfDay(addYears(new Date(), 1)),
  },
  find: async (params) => {
    const res = await app
      .service(`activities`)
      .find({ query: { ...params, $limit: 300 } });
    set(
      produce((state) => {
        state.activities.all = res.data;
      }),
    );
  },
  findSuggestions: async (params) => {
    if (!params) params = {};
    if (!params.term) {
      params.$limit = 20;
      params.$select = [`category`];
    }
    const res = await app
      .service(`activities/suggestions`)
      .find({ query: params });
    set(
      produce((state) => {
        state.activities.suggestions = Array.isArray(res) ? res : [];
      }),
    );
  },
  setCurrentSuggestion: (suggestion) => {
    set(
      produce((state) => {
        state.activities.currentSearch = undefined;
        state.activities.currentSuggestion = suggestion;
      }),
    );
  },
  setCurrentSearch: (value: string) => {
    set(
      produce((state) => {
        state.activities.currentSuggestion = undefined;
        state.activities.currentSearch = value;
      }),
    );
  },
  setCurrentPeriod: (value) => {
    const { $gte, $lte } = value;
    if (isValidDate($gte) && isValidDate($lte)) {
      set(
        produce((state) => {
          state.activities.currentPeriod = value;
        }),
      );
    }
  },
});

export default activitiesSlice;
